"use client";

import { startTransition, useEffect } from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useRouter } from "next/navigation";
import { COUNTRIES, ERROR_FIXING, SOMETHING_WENT_WRONG, TRY_AGAIN_LATER } from "../../../constants/common/common";
import isMobile from "../../../utils/common";
import { PageTypes, setPageType } from "../../../utils/fnpPageType";
import { getMegaMenuContentAPI } from "../../../api/common";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, validationFor } from "../../../analytics/clevertapConstants";
import { usePathname } from "next/navigation";
import { shallowEqual, useSelector } from "react-redux";

const Header = dynamic(() => import("../../../components/organisms/header"));
const ButtonComponent = dynamic(() => import("../../../components/atoms/button"));
const Typography = dynamic(() => import("../../../components/atoms/typography"));
const CustomImage = dynamic(() => import("../../../components/atoms/customImage"));
const StickyBottomMenu = dynamic(() => import("../../../components/molecules/stickyFooter"));

/**
 *
 * @param {*} param0
 * @returns
 */
const ErrorBoundary = ({ error, reset }) => {
  const router = useRouter();
  const isMobileDevice = isMobile();
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const { loggedin } = useSelector(
    (state) => ({
      loggedin: state.fus.loginDetail?.loggedin,
    }),
    shallowEqual,
  );

  let menuItems = [];

  /**
   * To fetch the mega menu for desktop.
   */
  const fetchMenuData = () => {
    const menuResponse = getMegaMenuContentAPI(isMobileDevice, COUNTRIES.INDIA);
    menuItems = menuResponse?.menuJson ? JSON.parse(menuResponse?.menuJson) : [];
  };

  useEffect(() => {
    /**
     * set fnp page type for error page
     */
    setPageType(PageTypes.ERROR_500);

    if (!isMobileDevice) {
      fetchMenuData();
    }

    const errorValidationData = {
      page_name: pathname,
      page_type: PageTypes.ERROR_500,
      error_code: "500",
      validation_for: validationFor.SOMETHING_WENT_WRONG,
      validation_text: validationFor.SOMETHING_WENT_WRONG,
      login_status: loggedin,
    };

    trackEvent(cleverTapCommonEvents.validationViewed, errorValidationData, ["clevertap"]);

    console.error("Error: ", error.message);
  }, [error]);

  /**
   * Reloads the page with same url.
   */
  const onReload = () => {
    startTransition(() => {
      router.refresh();
      reset();
    });
  };

  return (
    <>
      <Header isMobile={isMobileDevice} isShowSearchIcon={false} menuData={menuItems} />
      <main>
        <div className="justify-content flex h-screen flex-col justify-center px-16">
          <CustomImage
            src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/assets/images/custom/service500.gif`}
            alt="Something went wrong"
            width={isMobileDevice ? 328 : 350}
            height={isMobileDevice ? 140 : 200}
            className="mx-auto"
          />
          <div className="flex flex-col">
            <h2 className="leading-28 mb-8 text-center text-16 font-600 leading-22 text-fnp-500 md:text-20">
              {SOMETHING_WENT_WRONG}
            </h2>
            <Typography Tag="p" classes="text-14 leading-18 font-400 text-fnp-500 text-center md:text-16 md:leading-20">
              {ERROR_FIXING}
              <br />
              {TRY_AGAIN_LATER}
            </Typography>
          </div>
          <ButtonComponent classes={`mt-30 md:w-max md:px-148`} onClick={onReload} label="Try Again" />
        </div>
      </main>
      {isMobile && <StickyBottomMenu catalogId={COUNTRIES.INDIA} />}
    </>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.object,
  reset: PropTypes.func,
};

export default ErrorBoundary;
